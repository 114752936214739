div#spinning-loader {
  width: 2rem;
  margin: auto;
  height: 2rem;
  border: 0.4rem solid rgba(255, 255, 255, .3);
  border-radius: 50%;
  border-top-color: #00CC89;
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
