div.Main {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: row;
  max-width: 64rem;
  width: 100%;
  min-height: 100%;
  height: min-content;
  margin: 0 auto;
  top: 0;
  background-color: #282c34;
}

.Main div#logo {
  height: 40vmin;
  pointer-events: none;
}
.Main div#side-header {
  border-right: 1px solid #AAAAAA;
}
.Main div#side-footer {
  border-left: 1px solid #AAAAAA;
}
.Main div#link {
  color: #61dafb;
}

.Main div#body {
  position: relative;
  flex-direction: column;
  color: lightgrey;
  width: inherit;
  min-width: 5rem;
  max-width: 38rem;
  height: 100%;
}

.Main div#subheader {
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  position: sticky;
  position: -webkit-sticky;
  padding: 0;
  border-bottom: 1px solid #AAAAAA;
  background-color: #282c34;
  top:0;
  width: 100%;
  max-width: 38rem;
  z-index: 8;
}
.Main div#subheader section {
  border: none;
}
.Main div#subheader section#title {
  display: flex;
  justify-content: center;
  margin: 0;
  border: none;
  font-family: 'MuseoModerno', sans-sarif;
  font-size: 2.15rem;
  font-weight: 400;
}
.Main div#subheader section#searchBar {
  justify-content: right;
  flex-direction: row-reverse;
}
.Main div#subheader p#subheader {
  margin: 0.5rem;
}

p {
  font-family: 'Quicksand', sans-serif;
  color: #00CC89;
}
small {
  justify-content: center;
  margin: 0 auto;
}
.Main div#subheader p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'MuseoModerno', sans-serif;
  margin: 0.2rem auto 0.6rem auto;
  padding: 0.5rem 0.4rem;
  transition: background-color 0.2s ease-in-out 0s;
  border-radius: 1rem;
}
.Main div#subheader p:hover {
  background-color: #1D1F22;
  transition: background-color 0.2s ease-in-out 0s;
  animation-name: swell1;
  animation-duration: 0.33s;
  animation-timing-function: ease-in-out;
}
.Main div#subheader p:active {
  background-color: #1D1F22;
  transition: background-color 0.2s ease-in-out 0s;
  animation-name: swell2;
  animation-duration: 0.33s;
  animation-timing-function: ease-in-out;
}
.Main div#subheader a#ume {
  margin: 0.8rem 0;
}
.Main div#subheader a {
  justify-content: center;
}
.Main div#subheader a:link {
  color: #00CC89;
  transition: color 0.125s ease-in-out 0s;
  text-decoration: none;
}
.Main div#subheader a:hover {
  color: #00CC89;
  text-decoration: none;
}
.Main div#subheader a:visited {
  color: #00CC89;
  text-decoration: none;
}
.Main div#subheader a:active {
  color: lightgrey;
  animation-name: swell2;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}
.Main div#subheader img {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.8rem;
  border-radius: 50%;
  margin: auto 0.1rem auto 0.5rem;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}

.Main div#subheader img:hover {
  background-color: #1D1F22;
  transition: background-color 0.3s ease-in-out;
}
.Main div#subheader section#sort-button {
  margin: 0.8rem 0.8rem 0.6rem 0;
}

/* Profile-specific subheader */
.Main div#subheader p#profile-subheader {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0.5rem 0.7rem;
  font-family: 'Quicksand', sans-serif;
  margin: 0.4rem 0 0.4rem 0.8rem;
}
.Main div#subheader p#profile-subheader span {
  margin: 0 auto 0 0;
  color: #CCDDDD;
}
.Main div#subheader p#profile-subheader span#username {
  margin: 0 auto 0.3rem 0;
  font-weight: 800;
  font-size: 1.5rem;
  word-break: break-all;
  max-width: 21rem;
}
.Main div#subheader p#profile-subheader span#memes {
  margin: 0 auto 0 0;
  font-size: 1rem;
}
/* Thread-specific subheader */
.Main div#subheader p#thread-subheader {
  font-weight: 700;
  color: #CCDDDD;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0.6rem 0.5rem;
  font-family: 'Quicksand', sans-serif;
  font-size: 1.8rem;
  margin: 0.4rem 0 0.4rem 0.7rem;
}

@keyframes swell1 {
  from {
    transform: scale(1);
  }
  33% {
    transform: scale(0.98);
  }
  66% {
    transform: scale(1.01);
    filter: blur(0.5px);
  }
  to {
    transform: scale(1);
    filter: blur(0px);
  }
}
@keyframes swell2 {
  from {
    transform: scale(1);
  }
  33% {
    transform: scale(0.97);
  }
  66% {
    transform: scale(1.03);
    filter: blur(0.5px);
  }
  to {
    transform: scale(1);
    filter: blur(0px);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    filter: blur(1px);
  }
  to {
    opacity: 1;
    filter: blur(0);
  }
}

@media screen and (max-width: 448px) {
  .Main div#subheader section#title p {
    font-size: 1.5rem;
  }
  .Main div#subheader section#sort-button {
    padding: 0.1rem;
    margin: 0 0.2rem 0 0;
    width: 6.5rem;
  }
  .Main div#subheader section#sort-button p.SortButton {
    margin: 0.5rem 0.2rem 0.5rem 0;
    padding: 0.1rem 0.1rem 0.2rem 0.1rem;
  }
  .Main div#subheader section#sort-button span {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 400px) {
  .Main div#side-footer {
    margin-right: 0.6rem;
  }
  .Main div#side-footer div#stats {
    display: none;
  }
  .Main div#body {
  }
}
@media screen and (max-width: 300px) {
  .Main div#subheader section#sort-button p.SortButton {
  }
}
