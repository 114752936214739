.CreateMeme div#background {
  z-index: 8;
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #AABBBB;
}
.CreateMeme div#container {
  z-index: 10;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 15%;
  left: 50%;
  margin-left: -18rem;
  padding: 0 1rem 0.5rem 1rem;
  border-radius: 1.5rem;
  display: flex;
  background-color: #282c34;
  opacity: 1;
  flex-direction: column;
}
.CreateMeme form#form {
  position: relative;
}
.CreateMeme form#form div#text-box {
  position:relative;
  border-bottom: 0.05rem solid #667777;
  margin: 1rem 0.2rem 0.2rem 0;
  width: 30rem;
  min-height: 7.5rem;
}

.CreateMeme section#head {
  border-bottom: 0.05rem solid #667777;
  width: 100%;
}
.CreateMeme section#body {
  display: flex;
  flex-direction: row;
  width: 34rem;
  color: #CCDDCC;
}
.CreateMeme img#profile-pic {
  width: 3.3rem;
  height: 3.3rem;
  border-radius: 100%;
  margin: 0.4rem 0.4rem;
  transform: translateX(-0.3rem);
  -moz-transform: translateX(-0.3rem);
}
.CreateMeme div#blank {
  width: 3.3rem;
  height: auto;
  border-radius: 100%;
  margin: 0.4rem 0.4rem;
  transform: translateX(-0.3rem);
  -moz-transform: translateX(-0.3rem);
}
.CreateMeme textarea#meme-text {
  border: none;
  outline: none;
  position: absolute;
  width: 30rem;
  background: rgb(0,0,0,0);
  caret-color: #667777;
  box-shadow: none;
  color: rgb(0,0,0,0);
  font-family: 'Avenir', sans-serif;
  line-height: 1.8rem;
  font-size: 1.4rem;
  letter-spacing: 0.01rem;
  font-weight: 500;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  resize: none;
  word-wrap: break-word;
  white-space: break-spaces;
  z-index:10;
  overflow: visible;
}
.CreateMeme div#text-box p#text-box {
  position: relative;
  justify-content: flex-start;
  border: none;
  box-shadow: none;
  color: #CCDDCC;
  line-height: 1.8rem;
  font-family: 'Avenir', sans-serif;
  font-size: 1.4rem;
  letter-spacing: 0.01rem;
  font-weight: 500;
  margin:0;
  padding: 0;
  width: 30rem;
  text-align: start;
  word-wrap: break-word;
  white-space: break-spaces;
  z-index:8;
}
.CreateMeme p#text-box span {
  margin: 0 auto 0 0;
  color: #CCDDCC;
  word-wrap: break-word;
  white-space: break-spaces;
}

.CreateMeme p#text-box span#plain {
  color: #CCDDCC;
}
.CreateMeme p#text-box span#at {
  color: #00CC89;
}
.CreateMeme p#text-box span#hash {
  color: #00CC89;
}

.CreateMeme textarea#meme-text::placeholder {
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
}

.CreateMeme p#meme-button {
  justify-content: center;
  width: 5rem;
  margin: 0.55rem 0.7rem 0.25rem auto;
  font-size: 1.2rem;
  font-weight: 800;
  background-color: #334646;
  color: #AABBAA;
  padding: 0.35rem 0.4rem 0.4rem 0.4rem;
  border-radius: 2rem;
  transition: background-color 0.15s;
}
.CreateMeme section#head {
  text-align: left;
  height: min-content;
}
.CreateMeme img#x.close {
  transform: translateX(-0.5rem);
  text-align: left;
  padding: 0.6rem;
  margin: 0.5rem auto 0 0;
  filter: brightness(20%) invert(1);
  cursor: pointer;
}

.CreateMeme div#button-box {
  display: flex;
  flex-direction: row;
}
.CreateMeme p#flag-grey {
  color: #667777;
  margin: 0;
}
.CreateMeme p#flag-red {
  color: #DD4422;
  font-weight: 700;
  margin: 0;
}
.CreateMeme div#button-box-small {
  display: none;
}
.CreateMeme div#background-small {
  display: none;
}

@media only screen and (max-width: 580px) {
  div.CreateMeme {
    display: flex;
    height: 100%;
    overflow-y: scroll;
  }
  .CreateMeme div#container {
    position: fixed;
    display: flex;
    top: 0%;
    left: 50%;
    margin-top: 0;
    margin-left: -50%;
    padding: 0;
    justify-content: center;
    border-radius: 0 0 1rem 1rem;
    width: 100%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .CreateMeme div#profilePic {
    position: sticky;
    -webkit-position: sticky;
    margin-left: 0.2rem;
  }
  .CreateMeme img#x.close {
    margin-left: 0.6rem;
  }
  .CreateMeme form {
    width: calc(100% - 1.6rem);
  }
  .CreateMeme form#form div#text-box {
    width: inherit;
  }
  .CreateMeme section#body {
    width: 100%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .CreateMeme textarea#meme-text {
    width: inherit;
  }
  .CreateMeme div#text-box p#text-box {
    width: inherit;
  }
  .CreateMeme div#text-box span {
    width: inherit;
  }
  .CreateMeme div#button-box {
    display: none;
  }
  .CreateMeme div#button-box-small {
    display: flex;
    width: 100%;
    margin: 0 1rem 0 1rem;
  }
  .CreateMeme div.counter {
    margin-left: 4.3rem;
  }
  .CreateMeme p#meme-button {
    height: min-content;
    margin-bottom: 0.6rem;
  }
}
