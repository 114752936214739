div.PageLoader {
  opacity: 0;
  margin: auto;
  position: relative;
  display: flex;
  width: 8rem;
  height: 8rem;
}
div#Border-Spinner {
  position: absolute;
  top: -2.4rem;
  left:-2.2rem;
  border-top: 1rem solid #00CC89;
  border-right: 1rem solid rgb(0,0,0,0);
  border-bottom: 1rem solid rgb(0,0,0,0);
  border-left: 1rem solid rgb(0,0,0,0);
  border-radius: 50%;
  padding: 1.2rem;
  width: inherit;
  height: inherit;
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;
}
div.PageLoader img {
  position: relative;
  width: inherit;
  height: auto;
  margin: auto 0;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
