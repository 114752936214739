div#container.CreateUser {
  margin: auto;
}
div#box {
  display: flex;
  flex-direction: column;
  margin: 0 0.4rem 0.4rem 0.4rem;
  padding-top: 0.7rem;
  border-radius: 3rem;
  box-shadow: 0 0 0 0.1rem #00CC89;
  width: 21rem;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
}
.CreateUser p#title {
  margin: 0 auto;
  opacity: 1;
  font-family: 'MuseoModerno', sans-serif;
  font-weight: 400;
  font-size: 6rem;
}

.CreateUser img#profile-pic {
  border: 0;
  border-radius: 100%;
  box-shadow: 0 0 0 0.1rem #00CC89;
}
.CreateUser div#profile-pic {
  width: min-content;
  margin: 0 auto;
  transform: translateX(-0.8rem);
}

.CreateUser form#CreateUser{
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 2rem 1rem;
  justify-content: center;
}
.CreateUser div#profilePic div#isEditable div#banner {
  margin-top: 0;
  left: -0.1rem;
  width: calc(140px + 0.2rem);
}
.CreateUser div#profilePic div#isEditable input[type=file]::file-selector-button,
.CreateUser div#profilePic div#isEditable input[type=file]::-webkit-file-upload-button {
  margin-top: 0;
}

.CreateUser p#username {
  margin: 1.2rem 1.5rem 1rem 1.5rem;
  word-wrap: break-word;
  font-size: 1.6rem;
  font-weight: 800;
  color: #CCDDCC;
}
.CreateUser p#address {
  margin: 0.5rem auto 0 auto;
  word-break: break-all;
  font-size: 1.2rem;
  font-weight: 500;
  font-family: 'Avenir', sans-serif;
  color: #CCDDCC;
}
.CreateUser p#description {
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 1.2rem;
  margin-top: 1rem;
  font-weight: 300;
}
.CreateUser p {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
}
.CreateUser p#field {
  justify-content: center;
  margin: 0;
}
.CreateUser p#button {
  justify-self: center;
  margin: 0 auto;
}
.CreateUser input#submit {
  justify-content: center;
  margin: 1.5rem auto 1rem auto;
  font-size: 1.0rem;
  background-color: #333334;
}
.CreateUser p label {
  margin: 1rem auto 0.05rem 0.7rem;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
}
.CreateUser #button input {
  display: flex;
  padding: 0.7rem 1rem 0.7rem 1rem;
  border-radius: 1rem;
  outline: none;
  box-shadow: none;
  border-width: 0;
  font-family: 'Quicksand', sans-serif;
  color: #CCDDDD;
}

.CreateUser p#field input {
  display: flex;
  margin: 0.2rem;
  padding: 0.75rem 1rem 0.7rem 1rem;
  border-radius: 1rem;
  outline: none;
  box-shadow: none;
  border-width: 0;
  font-size: 1.3rem;
  font-family: 'Quicksand', sans-serif;
  color: #CCDDDD;
  background-color: #666677;
  transition: box-shadow 0.5s ease-in-out;
}
.CreateUser #field input::placeholder {
  font-family: 'Quicksand', sans-serif;
  font-style: oblique;
  font-weight: 200;
  font-size: 0.85rem;
  color: #CCCDCD;
  padding-left: 0.25rem;
  margin: 0 0.2rem;
}
.CreateUser #field input#AddressInput {
  background: url('../../resources/at-thin.svg') no-repeat 7px 7px;
  background-color: #666677;
  background-size: 1.2rem;
  background-position-y: 1.025rem;
  background-position-x: 0.725rem;
  padding-left: 2.175rem;
}
.CreateUser span#subtext {
  font-family: Avenir;
  font-weight: 200;
  font-size: 0.9rem;
}
.CreateUser p#field input:focus,
.CreateUser p#field input:active {
  border-radius: 1rem;
  caret-color: lightgray;
  background-color: #282c34;
  color: #BBDDDD;
  transition: box-shadow 0.5s ease-in-out;
}
.CreateUser p#field input:-webkit-autofill,
.CreateUser p#field input:-webkit-autofill:hover,
.CreateUser p#field input:-webkit-autofill::first-line,
.CreateUser p#field input:-webkit-autofill:focus,
.CreateUser p#field input:-webkit-autofill:active {
  border-radius: 1rem;
  caret-color: lightgray;
  background-color: #282c34;
  -webkit-background-color: #282c34;
  box-shadow: 0 0 0 0.1rem #00CC89;
  transition: background-color 5000s ease-in-out 0s;
  color: lightgray;
  -webkit-text-fill-color: lightgray;
  font-family: Avenir;
  font-weight: 500;
  transition: box-shadow 0.5s ease-in-out;
}

@media screen and (max-width: 360px) {

  div#box {
    height: 100%;
    margin: 0;
    width: calc(100% - 0.2rem);
    padding: 0;
    box-shadow: none;
  }
  .CreateUser p#title {
    display: none;
  }
  .CreateUser p#username {
    margin: 0 0.2rem 1rem 0.2rem;
    word-break: break-all;
  }
  .CreateUser p#address {
    margin: 0 0.2rem 0 0.2rem;
  }
  .CreateUser p#description {
    margin: 1.5rem 2.5rem;
    line-height: 1.5rem;
  }
  .CreateUser form#CreateUser {
    padding: 0;
  }
}
