div.NotSupported {
  margin: auto;
}
.NotSupported p {
  font-size: 1.4rem;
}
.NotSupported p#p1 {
  opacity: 0;
}
.NotSupported p#p2 {
  opacity: 0;
}
.NotSupported span,
.NotSupported a {
  margin-bottom: 0.8rem;
}
.NotSupported span#ume {
  font-size: 5rem;
  font-family: 'MuseoModerno', sans-serif;
  font-weight: 400;
  line-height: 6rem;
}
.NotSupported span {
  font-weight: 400;
}
.NotSupported a {
  cursor: pointer;
  line-height: 3rem;
  text-decoration: none;
  font-weight: 700;
}
.NotSupported a:visited,
.NotSupported a:link,
.NotSupported a:active {
  text-decoration: none;
  color: #00CC89;
}
