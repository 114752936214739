p.downvote {
  cursor: pointer;
}
p.downvote:hover {
  filter: sepia(1) brightness(0.43) saturate(10000%) hue-rotate(180deg);
}
img.downvote {
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
}
