div.ThreadMemeMain {
  display: flex;
  flex-direction: column;
  background-color: #1D1F22;
  border-bottom: 1px solid #AAAAAA;
  width: inherit;
  overflow: hidden;
}
section#MainMeme.ThreadMemeMain {
  display: flex;
  position: relative;
  flex-direction: row;
  width: inherit;
}
.ThreadMemeMain div#ThreadMemeMain-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.5rem 0.5rem 0.5rem 0.687rem;
}
.ThreadMemeMain section#info {
  display: flex;
  flex-direction: column;
  margin: 0.2rem 0 auto 0.5rem;
  text-align: left;
}
.ThreadMemeMain div#ThreadMemeMain-header section#left {
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
  word-wrap: break-word;
}
.ThreadMemeMain div#ThreadMemeMain-header section#right p#delete {
  cursor: pointer;
  line-height: 0;
  display: flex;
  flex-wrap: nowrap;
  color: #CCDDDD;
  font-size: 2rem;
  align-items: center;
  margin: 0 0.2rem;
  padding: 0;
  vertical-align: top;
  transition: color 0.3s ease-in-out;
  white-space: pre;
}
.ThreadMemeMain div#ThreadMemeMain-header section#right p#delete:hover {
  color: #00CC89;
  transition: color 0.3s ease-in-out;
}
.ThreadMemeMain div#ThreadMemeMain-body {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.ThreadMemeMain div#ThreadMemeMain-footer {
  display: flex;
  flex-direction: row;
  margin: 0.6rem 8rem 0.2rem 1rem;
  justify-content: space-between;
  align-items: flex-end;
}
.ThreadMemeMain div#profilePic-main {
  margin-left: 1rem;
  height: 3.1rem;
}
.ThreadMemeMain img#profile-pic {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  margin: 0;
  border: 0.05rem solid rgb(0,0,0,0);
  transition: border 0.4s;
}
.ThreadMemeMain div#profilePic div#blank {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  margin: 0;
  border: 0.05rem solid rgb(0,0,0,0);
}
.ThreadMemeMain img#profile-pic:hover {
  border: 0.05rem solid rgb(255,255,255, 1);
  transition: border 0.4s;
}
.ThreadMemeMain section#profilePic {
  margin: 0.3rem 0.1rem 0 0.2rem;
}
.ThreadMemeMain div#text-box {
  display: inline;
  text-align: left;
  word-break: break-word;
  overflow-wrap: break-word;
}
.ThreadMemeMain p#ThreadMemeMain-text {
  margin: 0 1rem 1.2rem 1rem;
  width: inherit;
  font-family: 'Avenir', sans-serif;
  line-height: 2rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: #CCDDCC;
  justify-content: flex-start;
  border: none;
  box-shadow: none;
}
.ThreadMemeMain p#ThreadMemeMain-text span {
  display: inline;
  font-family: 'Avenir', sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  margin: 0;
  text-align: left;
  white-space: pre-line;
}

.ThreadMemeMain p#ThreadMemeMain-text span#plain {
  color: #CCDDCC;
  margin: 0;
}
.ThreadMemeMain p#ThreadMemeMain-text a#at,
.ThreadMemeMain p#ThreadMemeMain-text a#hash {
  display: inline;
  margin: 0;
  color: #00CC89;
  text-decoration: none;
}
.ThreadMemeMain p#ThreadMemeMain-text a#at:hover,
.ThreadMemeMain p#ThreadMemeMain-text a#hash:hover {
  color: #00CC89;
  text-decoration: underline;
  width: inherit;
}

.ThreadMemeMain div#username {
  display: flex;
}
.ThreadMemeMain a#username {
  font-family: 'Quicksand', sans-serif;
  font-weight: 800;
  color: #CCDDCC;
  font-size: 1.1rem;
  margin: 0 0.3rem 0.4rem 0;
  width: min-content;
  white-space: pre;
  text-decoration: none;
  justify-content: left;
  align-items: center;
}
.ThreadMemeMain a#username:hover {
  text-decoration: underline;
}
.ThreadMemeMain div#ThreadMemeMain-footer p span {
  color: #FFFFFF;
}
.ThreadMemeMain div#ThreadMemeMain-footer p {
  margin: 0 0 0.2rem 0;
  padding: 0.2rem;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 2rem;
  border-radius: 1rem;
  position: relative;
  filter: invert(0.4);
}
.ThreadMemeMain span {
  font-family: 'Avenir-Bold', sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
  width: min-content;
  margin: 0 auto 0 0;
  color: #778888;
  white-space: pre;
}
.ThreadMemeMain span#address {
  font-family: 'Avenir-Bold', sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
  width: min-content;
  margin: 0 auto 0 0;
  color: #778888;
  white-space: pre;
  text-align: left;
}
.ThreadMemeMain div#time {
  width: auto;
  text-align: left;
  margin: 0 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #667777
}
.ThreadMemeMain span#time {
  margin: 0 auto 1rem 0;
}
.ThreadMemeMain div#stats {
  border-radius: 0;
  text-align: left;
  margin: 0 1rem;
  width: auto;
  padding: 0;
  border-bottom: 1px solid #667777;
}
.ThreadMemeMain div#stats p#like-stats {
  margin: 0.6rem 0.2rem 0.8rem 0.2rem;
  padding: 0;
}
.ThreadMemeMain p#like-stats span {
  font-size: 1rem;
  margin: 0;
  padding: 0;
}
.ThreadMemeMain p#like-stats span#like-count {
  color: #CCDDDD;
  font-size: 1.1rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
