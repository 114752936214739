div.ReplyInThread#ReplyInThread {
  margin: 0 1rem 1rem 1rem;
  /*width: 100%;*/
  height: auto;
}
.ReplyInThread div#background {
  background-color: #AABBBB;
}
.ReplyInThread div#reply-container {
  align-items: center;
  border-top: 0.05rem solid #667777;
  padding-top: 0.5rem;
  width: 100%;
  display: flex;
  opacity: 1;
  flex-direction: column;
}
.ReplyInThread form#reply-form {
  position: relative;
  justify-content: flex-start;
  width: calc(100% - 1.1rem);
}
.ReplyInThread form#reply-form div#reply-text-box {
  position:relative;
  margin: 0.4rem 0.2rem 0.2rem 0;
  width: inherit;
  white-space: break-word;
  align-items: flex-end;
}

.ReplyInThread div#reply-container div#reply-body {
  display: flex;
  flex-direction: row;
  width: inherit;
  color: #CCDDCC;
}
.ReplyInThread div#reply-profilePic img#profile-pic {
  position: relative;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  margin: 0.4rem 0.2rem 0 0;
  transform: translateX(-0.25rem);
}
.ReplyInThread textarea#reply-text {
  border: none;
  outline: none;
  position: absolute;
  width: inherit;
  background: rgb(0,0,0,0);
  caret-color: #667777;
  box-shadow: none;
  color: rgb(0,0,0,0);
  line-height: 1.8rem;
  font-family: 'Avenir', sans-serif;
  font-size: 1.4rem;
  letter-spacing: 0.01rem;
  font-weight: 500;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  resize: none;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: break-spaces;
  z-index:2;
  overflow: visible;
}
.ReplyInThread div#reply-text-box p#text-box {
  position: relative;
  width: inherit;
  justify-content: flex-start;
  border: none;
  box-shadow: none;
  color: #CCDDCC;
  font-family: 'Avenir', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  letter-spacing: 0.01rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
  text-align: start;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: break-spaces;
  z-index:0;
}
.ReplyInThread p#text-box span {
  margin: 0 auto 0 0;
  width: inherit;
  color: #CCDDCC;
  font-family: 'Avenir', sans-serif;
  letter-spacing: 0.01rem;
  font-size: 1.4rem;
  font-weight: 500;
  margin:0;
  padding: 0;
  text-align: start;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: break-spaces;
  z-index:0;
}

.ReplyInThread p#text-box span#plain {
  color: #CCDDCC;
}
.ReplyInThread p#text-box span#at {
  color: #00CC89;
  word-wrap: break-word;
  white-space: break-spaces;
}

.ReplyInThread p#text-box span#plain {
  color: #CCDDCC;
}
.ReplyInThread p#text-box span#at {
  color: #00CC89;
}
.ReplyInThread p#text-box span#hash {
  color: #00CC89;
}

.ReplyInThread textarea#reply-text::placeholder {
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  width: 80%;
}

.ReplyInThread div#reply-button-box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
}

.ReplyInThread p#reply-submit {
  font-size: 1rem;
  font-weight: 800;
  background-color: #334646;
  color: #AABBAA;
  padding: 0.45rem 1rem 0.5rem 1rem;
  margin: 0 0 auto auto;
  border-radius: 2rem;
  transition: background-color 0.15s;
}

.ReplyInThread p#flag-grey {
  color: #667777;
  margin: 0 auto 0 3.5rem;
}
.ReplyInThread p#flag-red {
  color: #DD4422;
  font-weight: 700;
  margin: 0 auto 0 3.5rem;
}
.ReplyInThread section#reply-header {
  position: relative;
  text-align: left;
  width: inherit;

}
.ReplyInThread p#replying {
  width: inherit;
  text-align: left;
  margin: 0 0 0.3rem 0;
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.ReplyInThread p span#replying{
  margin: 0;
  word-break: break-word;
}
.ReplyInThread p#replying a#at,
.ReplyInThread p#replying a#hash {
  font-family: 'Avenir', sans-serif;
  display: inline;
  margin: 0;
  color: #00CC89;
  text-decoration: none;
  line-break: anywhere;
}
.ReplyInThread p#replying a#at:hover,
.ReplyInThread p#replying a#hash:hover {
  color: #00CC89;
  text-decoration: underline;
}
.ReplyInThread p span#parent {
  height: auto;
  color: #00CC89;
  margin: 0;
  word-break: break-word;
}
.ReplyInThread p span#parent:hover {
  cursor: pointer;
  text-decoration: underline;
}
