div#downvote-popup {
  transform: translateX(-4.4rem) translateY(-7rem);
  -moz-transform: translateX(-4.4rem) translateY(-7rem);
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: absolute;
  background-color: #1D1F22;
  padding: 0.4rem;
  color: #CCDDDD;
  border: 0.2rem solid #4F56DF;
  border-radius: 1rem;
  box-shadow: 0px 0px 8px #667777;
  vertical-align: middle;
  align-items: center;
  white-space: pre;
}

div#downvote-popup form div#boost {
  margin: 0.2rem 0.1rem;
  display: flex;
  flex-direction: column;
  text-align: center;
}

div#downvote-popup form label#boost {
  margin: 0.2rem 0.3rem 0.7rem 0.3rem;
}
div#downvote-popup div#boost div#boost-field {
  display: inline-block;
  border: 0.2rem solid #667777;
  box-shadow: none;
  border-radius: 1rem;
  padding: 0;
  margin: 0;
  transition: border 0.2s ease-in-out;
}
div#downvote-popup form input {
  background-color: #1D1F22;
  box-shadow: none;
  border: none;
  margin: 0;
  padding: 0.4rem 0 0.5rem 0;
  font-size: 1.1rem;
  color: #CCDDDD;
  outline: none;
  display: inline-flex;
  flex-direction: row;
  width: 5rem;
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
}

div#downvote-popup form span{
  font-family: sans-serif;
  display: inline;
  border: none;
  padding: 0 0.6rem;
  white-space: pre;
  font-size: 1.4rem;
}
div#downvote-popup form span#increase {
  padding: 0 0.6rem 0 0;
}
div#downvote-popup form span#decrease,
div#downvote-popup form span#increase {
  filter: sepia(0) brightness(1) saturate(0%) hue-rotate(12deg);
  transition: filter 0.3s ease-in-out;
  cursor: pointer;
}
div#downvote-popup form span#decrease:hover ,
div#downvote-popup form span#increase:hover {
  transition: filter 0.3s ease-in-out;
  filter: sepia(1) brightness(0.8) saturate(100%) hue-rotate(12deg);
}

div#downvote-popup form span#boost {
  background-color: #667777;
  width: min-content;
  font-family: 'Quicksand', sans-serif;
  font-weight: 800;
  font-size: 1rem;
  text-align: center;
  margin: 0.6rem auto 0.6rem auto;
  padding: 0.2rem 1rem;
  border-radius: 1rem;
}

div#downvote-popup input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
div#downvote-popup input[type="number"]::-webkit-inner-spin-button,
div#downvote-popup input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
div#downvote-popup input::-webkit-calendar-picker-indicator {
  display: none;
}
div#downvote-popup datalist {
  display: none;
}
