div#stats-container {
  display: flex;
  flex-direction: column;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  border-left: 0.05rem;
  width: 13rem;
  z-index: 0;
}
div#stats {
  width: 11.4rem;
  margin: 0.8rem;
  transition: background-color 1s ease-in-out;
  border-radius: 1.5rem;
}
div#side-footer div#loader {
  width: 13rem;
  margin: 6rem 0;
}

div#stats:hover {
  background-color: #1D1F22;
}

div#stats div#stats-header {
  width: inherit;
  border: none;
  display: flex;
  flex-direction: column;
}

div#stats div#stats-header img {
  width: 4rem;
  height: 4rem;
  margin: 0 auto;
  border: 0.13rem solid #CCDDDD;
  border-radius: 50%;
}
div#stats div#stats-header div#blank {
  width: 3.5rem;
  height: 3.5rem;
  margin: 0 auto;
  border-radius: 50%;
}

div#stats p#info {
  margin: 0 0.8rem 0.8rem 0.8rem;
  word-break: break-all;
  line-height: 1.4rem;
  text-align: center;
}

div#stats a,
div#stats a:hover,
div#stats a:visited {
  color: #CCDDDD;
  padding-top: 1rem;
  word-break: all;
  text-decoration: none;
}

div#stats p#stats {
  width: inherit;
  margin: 0 0.6rem;
  padding: 1rem 0;
  border-top: 0.05rem solid #667777;
  border-bottom: 0.05rem solid #667777;
  color: #CCDDDD;
  font-weight: 500;
  text-align: left;
  word-break: break;
}
div#stats span#username {
  font-size: 1.2rem;
  font-weight: 700;
}
div#stats p#balance {
  width: inherit;
  margin: 0;
  padding: 0.6rem 0.6rem 0.7rem 0.6rem;
  color: #00CC89;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  word-break: break;
}
div#stats span#label {
  font-weight: 500;
  font-size: 1rem;
  word-break: break-all;
}
div#stats p#balance span#label {
  font-size: 0.9em;
}
div#stats p#balance span#bold-label {
  font-weight: 800;
  font-size: 0.9em;
}
div#stats p#balance span#data {
  align-items: center;
  font-weight: 700;
  font-size: 1.5rem;
}
div#stats p#balance img {
  transform: translateY(0.05rem);
  width: 1.4rem;
  margin: auto 0.3rem auto 0;
}

@media only screen and (max-width: 800px) {
  .Main div#side-footer {
    border-right: none;
  }
  div#stats-container {
    display: flex;
    width: min-content;
  }
  div#stats div#stats-header img {
    width: 3.2rem;
    height: 3.2rem;
  }
  div#stats {
    display: flex;
    flex-direction: column;
    min-width: 3.4rem;
    width: min-content;
  }
  div#side-footer div#loader {
    display: flex;
    width: 6rem;
  }
  div#stats div#stats-header {
    padding: 0 0.5rem;
  }
  div#stats p#info {
    display: none;
  }
  div#stats p#stats {
    display: none;
  }
  div#stats p#balance {
    display: inline-flex;
    padding: 0.5rem 0.5rem 1rem 0.5rem;
    height: auto;
    word-wrap: break-word;
  }
  div#stats p#balance span#label,
  div#stats p#balance span#bold-label {
    display: none;
  }

}
