p.LikeButton-Liked {
  filter: sepia(1) brightness(0.4) saturate(10000%) hue-rotate(285deg);
  cursor: pointer;
}
p.LikeButton {
  cursor: pointer;
}
p.LikeButton:hover {
  filter: sepia(1) brightness(0.4) saturate(10000%) hue-rotate(285deg);
}
