div.ChildMeme-Parent {
  width: inherit;
  background-color: #1D1F22;
}
div.ChildMeme {
  overflow: hidden;
}
div.ChildMeme:hover {
  background-color: #313131;
  cursor: pointer;
}
.ChildMeme div#ChildMeme-container {
  display: flex;
  margin: 0 1rem;
}
.ChildMeme div#ChildMeme-body {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.ChildMeme div#ChildMeme-header {
  display: flex;
  text-align: left;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0.1rem 0 0.5rem 0;
  align-items: flex-end;
}
.ChildMeme div#ChildMeme-header section#left {
  word-wrap: break-word;
}
.ChildMeme div#ChildMeme-header section#right p#delete {
  flex-wrap: nowrap;
  line-height: 0;
  transform: translateY(-1rem);
  color: #CCDDDD;
  font-size: 2rem;
  align-items: center;
  margin: 0 0.2rem;
  padding: 0;
  vertical-align: top;
  transition: color 0.3s ease-in-out;
  white-space: pre;
}
.ChildMeme div#ChildMeme-header section#right p#delete:hover {
  color: #00CC89;
  transition: color 0.3s ease-in-out;
}
.ChildMeme div#ChildMeme-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 2rem;
  align-items: flex-end;
  justify-content: space-between;
  overflow-y: hidden;
  margin: 0.3rem 20% 0.1rem 0;
}
.ChildMeme img#profile-pic {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  margin: 0;
  border: 0.05rem solid rgb(0,0,0,0);
  transition: border 0.4s;
  transform: translateX(-0.3rem);
  -moz-transform: translateX(-0.3rem);
}
.ChildMeme div#profilePic div#blank {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  margin: 0;
  border: 0.05rem solid rgb(0,0,0,0);
}
.ChildMeme div#profilePic {
  height: 3.125rem;
}
.ChildMeme img#profile-pic:hover {
  border: 0.05rem solid rgb(255,255,255, 1);
  transition: border 0.4s;
}
.ChildMeme section#profilePic {
  margin: 0 0.1rem 0 0;
  height: inherit;
}

.ChildMeme div#text-box {
  display: inline;
  text-align: left;
  word-break: break-word;
  overflow-wrap: break-word;
}
.ChildMeme p#ChildMeme-text {
  margin: 0 0.3rem 0 0;
  width: inherit;
  padding: 0;
  font-family: 'Arial', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #CCDDCC;
  justify-content: flex-start;
  border: none;
  box-shadow: none;
}
.ChildMeme p#ChildMeme-text span {
  font-family: 'Arial', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  text-align: left;
}

.ChildMeme p#ChildMeme-text span#plain {
  color: #CCDDCC;
  margin: 0;
}
.ChildMeme p#ChildMeme-text a#at,
.ChildMeme p#ChildMeme-text a#hash {
  margin: 0;
  color: #00CC89;
  text-decoration: none;
}
.ChildMeme p#ChildMeme-text a#at:hover,
.ChildMeme p#ChildMeme-text a#hash:hover {
  color: #00CC89;
  text-decoration: underline;
}

.ChildMeme a#username {
  font-family: 'Quicksand', sans-serif;
  font-weight: 800;
  text-align: left;
  color: #CCDDCC;
  font-size: 1.05rem;
  margin: 0 0.4rem 0 0;
  text-decoration: none;
  word-break: break-word;
}
.ChildMeme a#username:hover {
  text-decoration: underline;
}
.ChildMeme span {
  font-family: 'Avenir', sans-serif;
  font-size: 0.8rem;
  color: #778888;
}
.ChildMeme div#ChildMeme-footer p span {
  color: #FFFFFF;
}
.ChildMeme div#ChildMeme-footer p {
  margin: 0.15rem;
  padding: 0.2rem;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 2rem;
  justify-content: space-between;
  border-radius: 1rem;
  position: relative;
  filter: invert(0.4);
}
.ChildMeme p span {
  margin: 0 0.1rem 0 0.4rem;
  overflow-wrap: break-word;
}
.ChildMeme span#address {
  margin: 0 0.4rem 0 0;
  word-break: break-word;
}
.ChildMeme span#time {
  margin: 0 0.4rem 0 0;
  word-break: break-word;
}

.ChildMeme div.vl {
  transform: translateX(-0.3rem);
  -moz-transform: translateX(-0.3rem);
  border-left: 0.1rem solid #667777;
  margin: 0 auto;
  width: 0;
  height: 100%;
}
.ChildMeme-Parent div.show-replies {
  display: flex;
  flex-direction: row;
  background-color: #1D1F22;
  cursor: pointer;
  width: inherit;
}
.ChildMeme-Parent div#show-replies {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 0.05rem solid #667777;
  margin: 0 1rem;
}
.ChildMeme-Parent p#show-replies {
  margin: 0 auto;
  padding: 0.8rem 3rem 0.8rem 0;
}
.ChildMeme-Parent div#line-box {
  width: 3.1rem;
}
.ChildMeme-Parent div.dotted-line {
  transform: translateX(-0.3rem);
  -moz-transform: translateX(-0.3rem);
  border-left: 0.1rem dashed #667777;
  width: 0;
  margin: 0 auto;
  height: 1.5rem;
}
div#deleted p {
  background-color: #1D1F22;
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
}
div#deleted:hover {
  background-color: #1D1F22;
  cursor: default;
}

@media screen and (max-width: 475px) {
  .ChildMeme-Parent div#line-box {
    display: none;
  }
  .ChildMeme-Parent p#show-replies {
    padding: 0.8rem 0;
  }
}
