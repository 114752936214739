.Reply div#background {
  z-index: 8;
  display:block;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #AABBBB;
}
.Reply div#container {
  z-index: 10;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 15%;
  left: 50%;
  margin-left: -18rem;
  padding: 0 1rem 0.5rem 1rem;
  border-radius: 1.5rem;
  display: flex;
  background-color: #282c34;
  opacity: 1;
  flex-direction: column;
  width: 34rem;
}
.Reply form#form {
  position: relative;
}
.Reply form#form div#text-box {
  position:relative;
  border-bottom: 0.05rem solid #667777;
  margin: 1rem 0.2rem 0.2rem 0rem;
  width: 30rem;
  min-height: 7.5rem;
}

.Reply section#head {
  border-bottom: 0.05rem solid #667777;
  text-align: left;

  width: 100%;
}
.Reply section#body {
  display: flex;
  flex-direction: row;
  width: 34rem;
  color: #CCDDCC;
}
.Reply section#body img#profile-pic {
  transform: translateX(-0.3rem);
  -moz-transform: translateX(-0.3rem);
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  margin: 0.4rem;
}
.Reply section#body div#blank {
  transform: translateX(-0.3rem);
  -moz-transform: translateX(-0.3rem);
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  margin: 0.4rem;
}
.Reply textarea#meme-text {
  border: none;
  outline: none;
  position: absolute;
  width: 30rem;
  background: rgb(0,0,0,0);
  caret-color: #667777;
  box-shadow: none;
  color: rgb(0,0,0,0);
  line-height: 1.8rem;
  font-family: 'Avenir', sans-serif;
  font-size: 1.4rem;
  letter-spacing: 0.01rem;
  font-weight: 500;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  resize: none;
  word-wrap: break-word;
  white-space: break-spaces;
  z-index:10;
  overflow: visible;
}
.Reply div#text-box p#text-box {
  position: relative;
  justify-content: flex-start;
  border: none;
  box-shadow: none;
  color: #CCDDCC;
  line-height: 1.8rem;
  font-family: 'Avenir', sans-serif;
  font-size: 1.4rem;
  letter-spacing: 0.01rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
  width: 30rem;
  text-align: start;
  word-wrap: break-word;
  white-space: break-spaces;
  z-index:8;
}
.Reply p#text-box span {
  margin: 0 auto 0 0;
  color: #CCDDCC;
  word-wrap: break-word;
  white-space: break-spaces;
}

.Reply p#text-box span#plain {
  color: #CCDDCC;
}
.Reply p#text-box span#at {
  color: #00CC89;
}
.Reply p#text-box span#hash {
  color: #00CC89;
}

.Reply textarea#meme-text::placeholder {
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
}

.Reply p#meme-button {
  justify-content: center;
  width: 4.5rem;
  margin: 0.55rem 0.7rem 0.25rem auto;
  font-size: 1.2rem;
  font-weight: 800;
  background-color: #334646;
  color: #AABBAA;
  padding: 0.35rem 0.4rem 0.4rem 0.4rem;
  border-radius: 2rem;
  transition: background-color 0.15s;
}

.Reply img#x.close {
  transform: translateX(-0.4rem);
  padding: 0.6rem;
  margin: 0.5rem auto 0 0;
  filter: brightness(20%) invert(1);
  cursor: pointer;
}


.Reply div#button-box {
  display: flex;
  flex-direction: row;
}
.Reply p#flag-grey {
  color: #667777;
  margin: 0;
}
.Reply p#flag-red {
  color: #DD4422;
  font-weight: 700;
  margin: 0;
}
div.Reply div#button-box-small {
  display: none;
}

@media only screen and (max-width: 580px) {
  .Reply img#x.close {
    margin-left: 0.6rem;
  }
  div.Reply {
    display: flex;
    height: 100%;
  }
  .Reply div.ReplyMeme div#parentProfilePic {
    transform: translateX(0);
  }
  section#body div#profilePic {
    position: sticky;
    -webkit-position: sticky;
    margin-left: 0.15rem;
  }
  .Reply section#body img#profile-pic {
    margin: 0 0.2rem 0.4rem 0.4rem;
  }
  .Reply div#container {
    position: fixed;
    display: flex;
    top: 0%;
    left: 50%;
    margin-top: 0;
    margin-left: -50%;
    border-radius: 0 0 1rem 1rem;
    padding: 0;
    justify-content: center;
    width: 100%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .ReplyMeme div.vl {
    height: calc(100% - 3rem);
  }
  .Reply form {
    width: calc(100% - 1.6rem);
  }
  .Reply form#form div#text-box {
    width: inherit;
    margin-top: 0.4rem;
    border: none;
  }
  .Reply section#body {
    width: 100%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .Reply textarea#meme-text {
    width: inherit;
  }
  .Reply div#text-box p#text-box {
    width: inherit;
  }
  .Reply div#text-box span {
    width: inherit;
  }
  .Reply div#button-box {
    display: none;
  }
  div.Reply div#button-box-small {
    display: flex;
    width: 100%;
    margin: 0 1rem 0 1rem;
  }
  div.Reply div.counter {
    margin-left: 4.2rem;
  }
  .Reply p#meme-button {
    height: min-content;
    margin-bottom: 0.6rem;
  }
}
