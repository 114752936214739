div.ParentMeme {
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: #1D1F22;
  width: inherit;
  overflow: hidden;
}
div.ParentMeme:hover {
  background-color: #313131;
  cursor: pointer;
}
.ParentMeme div#ParentMeme-body {
  display: flex;
  width: 100%;
  margin-right: 0.5rem;
  flex-direction: column;
}
.ParentMeme div#ParentMeme-header {
  display: flex;
  justify-content: space-between;
  text-align: left;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0.5rem 0 0.5rem 0.4rem;
  align-items: flex-end;
}
.ParentMeme div#ParentMeme-header section#left {
  word-wrap: break-word;
}
.ParentMeme div#ParentMeme-header section#right p#delete {
  flex-wrap: nowrap;
  line-height: 0;
  transform: translateY(-1rem);
  color: #CCDDDD;
  font-size: 2rem;
  align-items: center;
  margin: 0 0.2rem;
  padding: 0;
  vertical-align: top;
  transition: color 0.3s ease-in-out;
  white-space: pre;
}
.ParentMeme div#ParentMeme-header section#right p#delete:hover {
  color: #00CC89;
  transition: color 0.3s ease-in-out;
}
.ParentMeme div#ParentMeme-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: hidden;
  margin-right: 20%;
  justify-content: space-between;
  align-items: flex-end;
  height: 2rem;
}
.ParentMeme img#profile-pic {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  margin: 0 0 0 0.4rem;
  border: 0.05rem solid rgb(0,0,0,0);
  transition: border 0.4s;
  transform: translateX(-0.2rem);
  -moz-transform: translateX(-0.2rem);
}
.ParentMeme div#profilePic div#blank {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  margin: 0;
  border: 0.05rem solid rgb(0,0,0,0);
}
.ParentMeme a#profilePic {
}
.ParentMeme div#profilePic {
  height: 3.1rem;
}
.ParentMeme img#profile-pic:hover {
  border: 0.05rem solid rgb(255,255,255, 1);
  transition: border 0.4s;
}
.ParentMeme section#profilePic {
  margin: 0.3rem 0.1rem 0 0.5rem;
  height: inherit;
}

.ParentMeme div#text-box {
  display: inline;
  text-align: left;
  word-break: break-word;
  overflow-wrap: break-word;
}
.ParentMeme p#ParentMeme-text {
  margin: 0 0.3rem 0 0.4rem;
  width: inherit;
  padding: 0;
  font-family: 'Arial', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #CCDDCC;
  justify-content: flex-start;
  border: none;
  box-shadow: none;
}
.ParentMeme p#ParentMeme-text span {
  font-family: 'Arial', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  width: inherit;
  margin: 0;
  text-align: left;
}

.ParentMeme p#ParentMeme-text span#plain {
  color: #CCDDCC;
  margin: 0;
}
.ParentMeme p#ParentMeme-text a#at,
.ParentMeme p#ParentMeme-text a#hash {
  display: inline;
  margin: 0;
  color: #00CC89;
  text-decoration: none;
}
.ParentMeme p#ParentMeme-text a#at:hover,
.ParentMeme p#ParentMeme-text a#hash:hover {
  color: #00CC89;
  text-decoration: underline;
  width: inherit;
}

.ParentMeme a#username {
  font-size: 1rem;
  font-family: 'Quicksand', sans-serif;
  font-weight: 800;
  text-align: left;
  color: #CCDDCC;
  margin: 0 0.4rem 0 0;
  text-decoration: none;
  word-break: break-word;
}
.ParentMeme a#username:hover {
  text-decoration: underline;
}
.ParentMeme span {
  font-family: 'Avenir', sans-serif;
  font-size: 0.8rem;
  color: #778888;
}
.ParentMeme div#ParentMeme-footer p span {
  color: #FFFFFF;
}
.ParentMeme div#ParentMeme-footer p {
  margin: 0.15rem;
  padding: 0.2rem;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 2rem;
  justify-content: space-between;
  border-radius: 1rem;
  position: relative;
  filter: invert(0.4);
}
.ParentMeme p span {
  margin: 0 0.1rem 0 0.4rem;
}
.ParentMeme span#address {
  margin: 0 0.4rem 0 0;
  word-break: break-word;
}
.ParentMeme span#time {
  margin: 0 0.4rem 0 0;
  word-break: break-word;
}



.ParentMeme div.vl {
  border-left: 0.12rem solid #667777;
  margin: 0 auto;
  width: 0;
  height: 100%;
}

div#Parent-deleted.ParentMeme {
}
div#Parent-deleted.ParentMeme p {
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
}
div#Parent-deleted.ParentMeme:hover {
  background-color: #1D1F22;
  cursor: default;
}
