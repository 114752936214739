div.Meme {
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: #1D1F22;
  border-bottom: 1px solid #AAAAAA;
  width: inherit;
  overflow-x: hidden;
}
div.Meme:hover {
  background-color: #313131;
  cursor: pointer;
}
.Meme div#meme-body {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.Meme div#meme-header {
  display: flex;
  text-align: left;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0.53rem 0.4rem 0.5rem 0.4rem;
  align-items: flex-end;
}
.Meme div#meme-header section#left {
  word-wrap: break-word;
}
.Meme div#meme-header section#right p#delete {
  flex-wrap: nowrap;
  line-height: 0;
  transform: translateY(-1rem);
  color: #CCDDDD;
  font-size: 2rem;
  align-items: center;
  margin: 0 0.2rem;
  padding: 0;
  vertical-align: top;
  transition: color 0.3s ease-in-out;
  white-space: pre;
}
.Meme div#meme-header section#right p#delete:hover {
  color: #00CC89;
  transition: color 0.3s ease-in-out;
}
.Meme div#meme-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  height: 2rem;
  overflow-y: hidden;
  justify-content: space-between;
  margin: 0.3rem 20% 0.1rem 0;
}
.Meme img#profile-pic {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  border-radius: 100%;
  margin: 0;
  border: 0.05rem solid rgb(0,0,0,0);
  transition: border 0.4s;
}
.Meme div#profilePic div#blank {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  margin: 0;
  border: 0.05rem solid rgb(0,0,0,0);
}
.Meme img#profile-pic:hover {
  border: 0.05rem solid rgb(255,255,255, 1);
  transition: border 0.4s;
}
.Meme section#profilePic {
  margin: 0.35rem 0.1rem 0 0.4rem;
}

.Meme div#text-box {
  display: inline;
  text-align: left;
  word-break: break-word;
  overflow-wrap: break-word;
}
.Meme p#meme-text {

  margin: 0 1.5rem 0 0.4rem;
  width: inherit;
  padding: 0;
  font-family: 'Arial', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #CCDDCC;
  justify-content: flex-start;
  border: none;
  box-shadow: none;
}
.Meme p#meme-text span {
  display: inline;
  font-family: 'Arial', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  text-align: left;
}

.Meme p#meme-text span#plain {
  color: #CCDDCC;
  margin: 0;
}
.Meme p#meme-text a#at,
.Meme p#meme-text a#hash {
  display: inline;
  margin: 0;
  color: #00CC89;
  text-decoration: none;
  line-break: anywhere;
}
.Meme p#meme-text a#at:hover,
.Meme p#meme-text a#hash:hover {
  color: #00CC89;
  text-decoration: underline;
}

.Meme span#address {
  word-break: break-all;
  margin: 0 0.3rem 0 0;
}
.Meme span#time {
  word-break: break-word;
  margin: 0 0.3rem 0 0;
}
.Meme a#username {
  font-family: 'Quicksand', sans-serif;
  font-weight: 800;
  text-align: left;
  color: #CCDDCC;
  font-size: 1.05rem;
  margin: 0 0.3rem 0 0;
  text-decoration: none;
  word-break: break-all;
}
.Meme a#username:hover {
  text-decoration: underline;
}
.Meme span {
  font-family: 'Avenir', sans-serif;
  font-size: 0.8rem;
  margin: 0 0.3rem;
  color: #778888;
}
.Meme div#meme-footer p span {
  color: #FFFFFF;
}
.Meme div#meme-footer p {
  margin: 0.15rem;
  padding: 0.2rem;
  flex-direction: row;
  flex-wrap: nowrap;
  width: min-content;
  white-space: pre;
  justify-content: space-between;
  border-radius: 1rem;
  position: relative;
  filter: invert(0.4);
}
.Meme p span {
  margin: 0 0.1rem 0 0.4rem;
}
.Meme p#upvote-button {
  margin: 0.15rem;
  cursor: pointer;
}
.Meme p#upvote-button:hover {
  filter: sepia(1) brightness(0.43) saturate(10000%) hue-rotate(310deg);
}
.Meme p#downvote-button {
  cursor: pointer;
}
.Meme p#downvote-button:hover {
  filter: sepia(1) brightness(0.43) saturate(10000%) hue-rotate(180deg);
}
.Meme img#downvote {
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
}

@media screen and (max-width: 385px) {
  .Meme div#meme-footer p {
    margin: 0.15rem 0;
  }
  .Meme div#meme-footer p.upvote,
  .Meme div#meme-footer p.downvote {
    width: 1.1rem;
  }
}
