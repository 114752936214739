p#loader.Thread {
  max-width: 38rem;
}
.Thread div#loader {
  opacity: 1;
  align-items: flex-start;
  padding: 1rem 0;
  border-right: 0.05 solid #AAAAAA;
  height: 100%;
}
div.Thread {
}
