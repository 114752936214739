div.Banners {
  position: fixed;
  top: 1%;
  left: 50%;
  margin-left: -7.525rem;
  z-index: 10;
}
div.Banner div#body {
  position: relative;
  margin-bottom: 0.4rem;
  border: none;
}
.Banner div#container {
  border: 0.15rem solid #CCDDDD;
  background-color: #282c34;
  box-shadow: 0px 0px 8px #667777;
  height: min-content;
  width: 15rem;
  padding: 0.4rem 1rem;
  border-radius: 1rem;
  transition: border 0.3s ease-in-out;
}
.Banner div#container-writing {
  border: 0.15rem solid #CCDDDD;
  background-color: #282c34;
  box-shadow: 0px 0px 8px #667777;
  height: min-content;
  width: 15rem;
  padding: 0.4rem 1rem;
  border-radius: 1rem;
  animation: shimmer-yellow 3s ease-in-out infinite;
}
.Banner div#container-loading {
  border: 0.15rem solid #CCDDDD;
  background-color: #282c34;
  box-shadow: 0px 0px 8px #667777;
  height: min-content;
  width: 15rem;
  padding: 0.4rem 1rem;
  border-radius: 1rem;
  animation: shimmer-orange 3s ease-in-out infinite;
}
.Banner p {
  margin: 0;
  padding: 0;
  height: auto;
  text-align: left;
}
.Banner p#Banner-header span {
  font-size: 0.9rem;
  font-style: Oblique;
  font-weight: 600;
}
.Banner p#Banner-body span {
  color: #CCDDDD;
  font-size: 0.9rem;
  font-weight: 500;
}
.Banner p#Banner-body span#plain {
  color: #CCDDDD;
  font-size: 0.9rem;
  font-weight: 500;
}
.Banner p#Banner-body span#highlight {
  color: #00CC89;
  font-size: 0.9rem;
  font-weight: 500;
}

@keyframes drift {
  from {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0.2rem);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes shimmer-yellow {
  from {
    border: 0.15rem solid #CCDDDD;
  }
  50% {
    border: 0.15rem solid #CCCC99;
  }
  to {
    border: 0.15rem solid #CCDDDD;
  }
}
@keyframes shimmer-orange {
  from {
    border: 0.15rem solid #CCDDDD;
  }
  50% {
    border: 0.15rem solid #FF4500;
  }
  to {
    border: 0.15rem solid #CCDDDD;
  }
}
@media screen and (max-width: 400px) {
  div.Banners {
    margin-left: -5.25rem;
  }
  div.Banner div#container,
  div.Banner div#container-loading,
  div.Banner div#container-writing {
    width: 10rem;
  }
}
