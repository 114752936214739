html {
  font-family: sans-serif;
  display: flex;
  width: 100%;
  height: 100%;
  font-family: 'Montserrat Alternates', sans-serif;
  background-color: #282c34;
  overflow: hidden;
}
div#root {
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
  background-color: #282c34;
  margin: 0;
}
div.App {
  overflow: auto;
  text-align: center;
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: #282c34;
}

small {
  justify-content: center;
  margin: 0 auto;
}

p {
  font-family: 'Quicksand', sans-serif;
  color: #00CC89;
}
