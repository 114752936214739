div.Timeline {
}
p#loader.Timeline {
  max-width: 38rem;
}
.Timeline div#loader {
  opacity: 1;
  align-items: flex-start;
  height: 100%;
}
.Timeline div#loaded p#loader {
  margin: 1rem 0;
}

div.Timeline div#loader div#spinning-loader {
  margin: 1rem auto;
}
div.Timeline div#loader-bottom div#spinning-loader {
  margin: 1rem auto 2rem auto;
}
