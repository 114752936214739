div.ReplyMeme {
  display: flex;
  flex-direction: row;
  margin: 0 auto 0 0;
  width: inherit;
}
.ReplyMeme div#meme-body {
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: left;
}
.ReplyMeme div#meme-header {
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0.4rem 0.1rem 0;
  align-items: flex-end;
  white-space: pre-line;
  overflow-wrap: word-break;
  word-break: break-word;
}
.ReplyMeme div#meme-footer {
  display: flex;
  flex-direction: row;
  margin: 0.2rem 1rem 0.2rem 0.4rem;
  justify-content: flex-start;
  align-items: flex-end;
  word-break: break-all;
}
.ReplyMeme img#profile-pic {
}

.ReplyMeme div#text-box {
  display: inline;
  text-align: left;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-line;
}
.ReplyMeme p#meme-text {
  margin: 0 1.2rem 0 0rem;
  width: calc(100% - 1rem);
  padding: 0;
  font-family: 'Avenir', sans-serif;
  font-size: 1rem;
  letter-spacing: 0.01rem;
  font-weight: 500;
  color: #CCDDCC;
  justify-content: flex-start;
  border: none;
  box-shadow: none;
  word-break: break-word;
}
.ReplyMeme p#meme-text span {
  display: inline;
  font-size: 1rem;
  letter-spacing: 0.01rem;
  font-weight: 500;
  margin: 0;
  text-align: left;
}

.ReplyMeme p#meme-text span#plain {
  color: #CCDDCC;
  margin: 0;
  word-break: break-word;
}
.ReplyMeme p#meme-text a#at,
.ReplyMeme p#meme-text a#hash {
  display: inline;
  margin: 0;
  color: #00CC89;
  text-decoration: none;
}
.ReplyMeme p#meme-text a#at:hover,
.ReplyMeme p#meme-text a#hash:hover {
  color: #00CC89;
  text-decoration: underline;
}
.ReplyMeme span#address {
  white-space: pre;
}
.ReplyMeme a#username {
  font-family: 'Quicksand', sans-serif;
  font-weight: 800;
  color: #CCDDCC;
  font-size: 1rem;
  margin: 0 0.3rem 0 0;
  text-decoration: none;
  word-wrap: break-word;
  white-space: pre;
}
.ReplyMeme span {
  font-family: 'Avenir', sans-serif;
  font-size: 0.8rem;
  margin: 0 0.3rem;
  color: #667777;
}
.ReplyMeme div#meme-footer p span {
  color: #FFFFFF;
}
.ReplyMeme div#meme-footer p {
  margin: 0.15rem 2rem 0.15rem 0.15rem;
  padding: 0.2rem;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 2rem;
  border-radius: 1rem;
  position: relative;
  filter: invert(0.4);
}
.ReplyMeme p span {
  margin: 0 0.1rem 0 0.4rem;
  word-break: break-word;
}
.ReplyMeme p {
  margin: auto auto 0 0;
}
.ReplyMeme p#replying {
  margin: auto auto 0 0;
}
.ReplyMeme p span#replying{
  height: auto;
  margin: 0;
}

.ReplyMeme p#replying a#at,
.ReplyMeme p#replying a#hash {
  font-size: 0.8rem;
  display: inline;
  margin: 0;
  color: #00CC89;
  text-decoration: none;
  line-break: anywhere;
}
.ReplyMeme p#replying a#at:hover,
.ReplyMeme p#replying a#hash:hover {
  color: #00CC89;
  text-decoration: underline;
}

.ReplyMeme p span#parent {
  height: auto;
  color: #00CC89;
  margin: 0;
}
.ReplyMeme p span#parent:hover {
  cursor: pointer;
  text-decoration: underline;
}
.ReplyMeme div.vl {
  border-left: 0.1rem solid #667777;
  margin: 0 auto;
  width: 0;
  min-height: 1.5rem;
  height: 100%;
  top: 0%;
}
.ReplyMeme div#parentProfilePic {
  transform: translateX(-0.3rem);
  -moz-transform: translateX(-0.3rem);
  margin: 0.4rem 0.4rem 0 0.4rem;
}
.ReplyMeme div#parentProfilePic div#profilePic {
  margin: 0 auto;
  height: 3rem;
  justify-content: center;
}
.ReplyMeme div#parentProfilePic div#profilePic img#profile-pic.ProfilePic {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
  justify-content: center;
}
