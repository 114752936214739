div.NoMetaMask {
  margin: auto;
}
.NoMetaMask p {
  font-size: 1.4rem;
}
.NoMetaMask p#p1 {
  opacity: 0;
}
.NoMetaMask p#p2 {
  opacity: 0;
}
.NoMetaMask span,
.NoMetaMask a {
  margin-bottom: 0.8rem;
}
.NoMetaMask span#ume {
  font-size: 5rem;
  font-family: 'MuseoModerno', sans-serif;
  font-weight: 400;
  line-height: 6rem;
}
.NoMetaMask span {
  font-weight: 700;
}
.NoMetaMask a#install {
  cursor: pointer;
  line-height: 8rem;
  text-decoration: none;
}
.NoMetaMask a#download {
  cursor: pointer;
  line-height: 8rem;
  text-decoration: none;
}
.NoMetaMask a:visited,
.NoMetaMask a:link,
.NoMetaMask a:active {
  text-decoration: none;
  color: #00CC89;
}
