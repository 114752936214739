div.NoWallet {
  margin: auto;
  line-height: 3rem;
}
.NoWallet p#p1 {
  font-size: 1.4rem;
  opacity: 0;
}
.NoWallet p#p2 {
  font-size: 1.4rem;
  opacity: 0;
}
.NoWallet p#p3 {
  opacity: 0;
}
