p#loader.Profile {
  margin: 1rem 0;
  max-width: 38rem;
}
.Profile div#loader {
  margin: 1rem 0;
  opacity: 1;
  align-items: flex-start;
  height: 100%;
}

div.Profile div#loader div#spinning-loader {
  margin: 1rem auto;
}
div.Profile div#loader-bottom div#spinning-loader {
  margin: 1rem auto 2rem auto;
}
div.Profile div#loaded p#loaded {
  margin: 1rem 0;
}

.Profile div#Profile {
  display: flex;
  flex-direction: column;
  font-family: 'Quicksand', sans-serif;
  border-bottom: 0.05rem solid #AAAAAA;
  background-color: #1D1F22;
}
.Profile div#Profile span {
  color: #778888;
}

/* header */
/* info section */
.Profile div#Profile div#header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: none;
}
.Profile div#Profile div#left {
  margin: 0.6rem 0.6rem 0 1rem;
}
.Profile div#Profile div#profilePic {
  text-align: left;
  margin-bottom: 0.5rem;
}
.Profile div#Profile img#profile-pic {
  border: 0.05rem solid #CCDDDD;
  border-radius: 50%;
  transition: filter 0.2s ease-in-out;
  cursor: pointer;
}
.Profile div#Profile div#blank {
  border: 0.05rem solid #CCDDDD;
  border-radius: 50%;
  transition: filter 0.2s ease-in-out;
  cursor: pointer;
}
.Profile div#Profile img#profile-pic:hover {
  filter: brightness(0.9);
  transition: filter 0.2s ease-in-out;
}
.Profile div#Profile p#info {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  margin: 0.2rem 0 0 0;
}
.Profile div#Profile div#left span#username {
  color: #CCDDDD;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: 800;
}
.Profile div#Profile div#left span#address {
  font-family: 'Avenir', sans-serif;
}
/* edit/follow button */
.Profile div#Profile div#right p {
  color: #CCDDDD;
  font-weight: 700;
  padding: 0.6rem 1rem;
  margin: 0.6rem 1rem;
  border: 0.05rem solid #CCDDDD;
  border-radius: 3rem;
  cursor: pointer;
  transition: background-color 0.33s ease-in-out;
}
.Profile div#Profile div#right p:hover {
  transition: background-color 0.33s ease-in-out;
  background-color: #2A2A2A;
}
.Profile div#Profile div#right p#following {
  color: #1D1F22;
  font-weight: 700;
  background-color: #CCDDDD;
  padding: 0.6rem 1rem;
  margin: 0.6rem 1rem;
  border: 0.05rem solid #CCDDDD;
  border-radius: 3rem;
  cursor: pointer;
  transition: background-color 0.33s ease-in-out;
}
.Profile div#Profile div#right p#following:hover {
  transition: background-color 0.33s ease-in-out;
  background-color: #AABBBB;
}

/* body */
.Profile div#Profile div#body {
  margin: 0.6rem 1rem;
  text-align: left;
  border: none;
}
.Profile div#Profile div#body p#bio {
  margin: 0 1.5rem 0.5rem 0rem;
  width: inherit;
  padding: 0;
  font-family: 'Arial', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #CCDDCC;
  justify-content: flex-start;
  border: none;
  box-shadow: none;
}
.Profile p#bio span {
  display: inline;
  font-family: 'Arial', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  text-align: left;
}

.Profile p#bio span#plain {
  color: #CCDDCC;
  margin: 0;
}
.Profile p#bio a#at,
.Profile p#bio a#hash {
  display: inline;
  margin: 0;
  color: #00CC89;
  text-decoration: none;
  line-break: anywhere;
}
.Profile p#bio a#at:hover,
.Profile p#bio a#hash:hover {
  color: #00CC89;
  text-decoration: underline;
}


/* footer */
.Profile div#Profile div#footer {
  display: flex;
  flex-direction: row;
  margin: 0 1rem;
}
.Profile div#Profile div#footer p {
  margin: 0 1.2rem 0.6rem 0;
  cursor: pointer;
}
.Profile div#Profile div#footer p span#count {
  color: #CCDDDD;
  font-weight: 800;
}

@media screen and (max-width: 485px) {
  div#Profile div#header img {
    width: 4rem;
    height: auto;
  }
}
@media screen and (max-width: 380px) {
  .Main div#subheader span#memes {
    display: none;
  }
  .Main div#subheader p#profile-subheader {
    word-break: break-all;
    white-space: pre-wrap;
  }
  div#Profile div#header img {
    width: 3.5rem;
    height: auto;
  }
  div#Profile div#header div#blank {
    width: 3.5rem;
    height: auto;
  }
}
@media screen and (max-width: 275px) {
  div#Profile div#header div#right p {
    display: none;
  }
}
