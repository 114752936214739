nav.navbar {
  position: sticky;
  position: -webkit-sticky;
  width: 13rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  top:0;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  z-index:1;
}
.navbar small#logo {
  border-radius : 30%;
  transition: background-color 0.2s ease-in-out 0s;
}
.navbar small#logo:hover {
  background-color: #1D1F22;
  transition: background-color 0.2s ease-in-out 0s;
  animation-name: swellNav1;
  animation-duration: 0.33s;
  animation-timing-function: ease-in-out;
}
.navbar small#logo:active {
  background-color: #1D1F22;
  transition: background-color 0.2s ease-in-out 0s;
  animation-name: swellNav2;
  animation-duration: 0.33s;
  animation-timing-function: ease-in-out;
}

.navbar a {
  transition: background-color 0.2s ease-in-out 0s;
  margin: 0 auto;
  align-items: center;
  border-radius: 2rem;
}
.navbar a:link {
  color: #00CC89;
  transition: color 0.125s ease-in-out 0s;
  text-decoration: none;
}
.navbar a:hover {
  background-color: #1D1F22;
  transition: background-color 0.2s ease-in-out 0s;
  animation-name: swellNav1;
  animation-duration: 0.33s;
  animation-timing-function: ease-in-out;
}
.navbar a:active {
  animation-name: swellNav2;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}
.navbar a {
  font-size: 1.2rem;
  padding: 0.6rem;
  margin-left: auto;
  align-items: center;
}
/* logo */
.navbar small#logo {
  margin: 0.35rem auto;
  cursor: pointer;
}
.navbar p#logo {
  margin: 0;
}
.navbar img#logo {
  font-size: 1rem;
  color: #00CC89;
  margin: 0.2rem auto 0 auto;
  padding: 0.7rem 0.7rem 0.5rem 0.7rem;
  width: 3.5rem;
}
.navbar img#logo:active {
  animation-name: blurExtra;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
}
/* page links */
.navbar a {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color: #00CC89;
  font-size: 1.5rem;
  margin: 0 auto 0 0.3rem;
  padding: 0;
}
.navbar a p {
  margin: 0;
  padding: 0.6rem;
}
.navbar a img#icon {
  margin: 0.3rem 1rem 0.3rem 0.2rem;
}
.navbar p > * {
  vertical-align: middle;
}
.navbar a span#link {
  margin: 0.2rem 0.45rem 0.2rem 0;
  padding-bottom: 1rem;
}

/* link-specific settings */
.navbar a#home p#home img#icon{
  transform: translateY(-0.1rem);
  -moz-transform: translateY(-0.1rem);
  width: 1.75rem;
  margin: 0.14rem 1rem 0.14rem 0.15rem;
}
.navbar a#profile p#profile img#icon{
  transform: translateY(-0.125rem);
  -moz-transform: translateY(-0.125rem);
  width: 1.9rem;
  margin: 0.14rem 1rem 0.14rem 0.1rem;
}
.navbar a#profile p#profile span#link {
  padding-bottom: 1.2rem;
}

/* meme button */
.navbar p#meme,
.navbar p#meme-small {
  display: block;
  font-size: 1.6rem;
  color: #FFFFFF;
  background-color: #00CC89;
  padding: 0.7rem 2rem 0.9rem 2rem;
  margin: 0.7rem 0.3rem;
  width: 8rem;
  border-radius: 3rem;
  transition: background-color 0.2s ease-in-out 0s;
}
.navbar p#meme:hover,
.navbar p#meme-small:hover {
  background-color: #00AB79;
  color: #FFFFFF;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out 0s;
  animation-name: swellNav1;
  animation-duration: 0.33s;
  animation-timing-function: ease-in-out;
}
.navbar p#meme:active,
.navbar p#meme-small:active {
  background-color: #00CC89;
  transition: background-color 0.2 ease-in-out 0s;
}
.navbar p#meme span {
  margin: 0 auto;
}
.navbar p#meme-small {
  display: none;
}


@keyframes swellNav1 {
  from {
    transform: scale(1);
  }
  33% {
    transform: scale(0.96);
  }
  66% {
    transform: scale(1.01);
  }
  to {
    transform: scale(1);
  }
}
@keyframes swellNav2 {
  from {
    transform: scale(1);
  }
  33% {
    transform: scale(0.98);
  }
  66% {
    transform: scale(1.02);
  }
  to {
    transform: scale(1);
  }
}
@keyframes blurExtra {
  from {
  }
  66% {
    filter: blur(1px);
  }
  to {
    filter: blur(0px);
  }
}

@media only screen and (max-width: 800px) {
  nav.navbar {
    width: 6rem;
  }
  nav.navbar span#link {
    display: none;
  }
  .navbar img#logo {
    width: 3rem;
  }
  .navbar a p span#icon {
    margin: 0;
  }
  .navbar a#home p#home img#icon {
    margin: 0.2rem;
  }
  .navbar a#profile p#profile img#icon {
    padding: 0;
    margin: 0.2rem;
  }
  .navbar p#profile {
    padding: 0.4rem;
  }
  .navbar a {
    margin: 0 auto;
  }
  .navbar p#meme {
    display: none;
  }
  .navbar p#meme-small {
    display: flex;
    width: min-content;
    margin: 0.5rem auto;
    padding: 0 1.1rem;
  }
  .navbar p#meme-small span {
    margin: 0 auto 0.1rem auto;
    text-align: center;
    font-size: 3rem;
    padding: 0;
  }
}
@media only screen and (max-width: 300px) {
  nav.navbar {
    width: 4rem;
  }
  .navbar p#meme-small {
    padding: 0 0.7rem;
  }
  .navbar img#logo {
    width: 2.5rem;
  }
}
