img#profile-pic {
  margin: 0;
  object-fit: cover;
}
div#profilePic {
  margin: 0;
}
div#profilePic div#blank {
  margin: 0;
  padding: 0;
  background-color: #1D1F22;
}
div#profilePic div#isEditable {
  position: relative;
  width: 140px;
  height: 140px;
  padding-bottom: 0.8rem;
}
div#profilePic div#isEditable img#profile-pic {
  position: fixed;
  margin-left: 0.7rem;
  border: none;
  width: 140px;
  height: 140px;
}
div#profilePic div#isEditable div#banner {
  position: absolute;
  background-color: rgb(0,0,0,0.4);
  margin-left: 0.7rem;
  margin-top: 0.8rem;
  top: 45px;
  left: -0.1rem;
  clip-path: circle(66%);
  width: calc(140px + 0.2rem);
  height: 50px;
}
div#profilePic div#isEditable input[type=file]::file-selector-button,
div#profilePic div#isEditable input[type=file]::-webkit-file-upload-button {
  position: absolute;
  margin-left: 0.7rem;
  margin-top: 0.7rem;
  background-color: rgb(0,0,0,0);
  border-radius: 100%;
  border: none;
  width: 140px;
  height: 140px;
  color: #CCDDDD;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
div#profilePic div#isEditable input[type=file]::file-selector-button:hover,
div#profilePic div#isEditable input[type=file]::-webkit-file-upload-button:hover {
  background-color: rgb(0,0,0,0.2);
  transition: background-color 0.2s ease-in-out;
}
div#profilePic input[type=file] {
  color: rgb(0,0,0,0);
}
