.EditProfile div#background {
  z-index: 8;
  display:block;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #AABBBB;
}
.EditProfile div#container {
  z-index: 10;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 15%;
  left: 50%;
  margin-left: -18rem;
  padding: 0.8rem 1rem 0.85rem 1rem;
  border-radius: 1.5rem;
  display: flex;
  background-color: #282c34;
  opacity: 1;
  flex-direction: column;
}

/* header */
.EditProfile section#head {
  display: flex;
  flex-direction: row;
  text-align: left;
  margin: 0 auto 0 0;
  width: 100%;
  border-bottom: 0.05rem solid #667777;
}
.EditProfile img#x.close {
  padding: 0.8rem;
  text-align: left;
  margin: auto 0;
  filter: brightness(20%) invert(1);
  cursor: pointer;
}
.EditProfile section#head p#title {
  color: #CCDDDD;
  font-size: 1.4rem;
  font-weight: 800;
  margin: auto 0;
}
.EditProfile div#button-box {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin: 0.25rem 0.5rem 0.25rem auto;
}
.EditProfile p#save-button {
  justify-content: center;
  width: 5rem;
  font-size: 1rem;
  font-weight: 800;
  background-color: #334646;
  color: #AABBAA;
  padding: 0.3rem;
  margin: 0 0 0.3rem 0;
  border-radius: 2rem;
  transition: background-color 0.15s;
}


.EditProfile section#body {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 34rem;
  color: #CCDDCC;
}
.EditProfile img#profile-pic {
  border-radius: 100%;
  margin: 0.8rem 0.4rem;
}
.EditProfile div#blank {
  border-radius: 100%;
  margin: 0.8rem 0.4rem;
}
.EditProfile div#profilePic {
  display: flex;
  flex-direction: column;
  width: min-content;
}

.EditProfile form#form {
  position: relative;
}
.EditProfile form#form label {
  font-size: 0.9rem;
  font-family: 'Avenir', sans-serif;
  font-weight: 600;
  color: #778888;
  margin: 0.4rem 0.4rem 0 0.4rem;
  display: flex;
  justify-content: space-between;
}
.EditProfile form#form label span#count {
  font-size: 0.8rem;
}
.EditProfile form#form div#edit-box {
  box-shadow: 0 0 0 0.1rem #667777;
  border: none;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
  margin: 0.6rem 0.6rem 0.3rem 0.6rem;
  width: inherit;
}

.EditProfile textarea {
  border: none;
  outline: none;
  position: relative;
  background: rgb(0,0,0,0);
  caret-color: #667777;
  box-shadow: none;
  color: #CCDDDD;
  font-family: 'Avenir', sans-serif;
  font-size: 1.25rem;
  letter-spacing: 0.01rem;
  font-weight: 500;
  top: 0;
  left: 0;
  margin: 0.2rem 0.4rem;
  padding: 0;
  resize: none;
  word-wrap: break-word;
  white-space: break-spaces;
  z-index:10;
  overflow: visible;
}


.EditProfile textarea#name-text::placeholder {
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
}

.EditProfile div.flag {
  margin: 0 0 0 1rem;
}
.EditProfile div.flag span#flag {
  color: #DD4422;
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  margin: 0;
}

@media only screen and (max-width: 580px) {
  div.EditProfile {
    display: flex;
    height: 100%;
    overflow-y: scroll;
  }
  .EditProfile div#container {
    position: fixed;
    display: flex;
    top: 0%;
    left: 50%;
    margin-top: 0;
    margin-left: -50%;
    padding: 0;
    justify-content: center;
    border-radius: 0 0 1rem 1rem;
    width: 100%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .EditProfile section#body div#profilePic {
    width: 10rem;
    position: sticky;
    -webkit-position: sticky;
    margin-left: 0.2rem;
  }
  .EditProfile img#x.close {
    margin-left: 0.6rem;
  }
  .EditProfile p#title {
    margin: 0.2rem 0;
  }
  .EditProfile form#form div#text-box {
    width: inherit;
  }
  .EditProfile section#body {
    width: 100%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #282c34;
    margin-bottom: 1rem;
  }
  .EditProfile textarea {
    width: inherit;
  }
  .EditProfile div#text-box p#text-box {
    width: inherit;
  }
  .EditProfile div#text-box span {
    width: inherit;
  }
  .EditProfile p#save-button {
    margin: auto 0;
  }
  .EditProfile div#button-box-small {
    display: flex;
    width: 100%;
    margin: 0 1rem 0 1rem;
  }
  .EditProfile div.counter {
    margin-left: 4.3rem;
  }
  .EditProfile p#meme-button {
    height: min-content;
    margin-bottom: 0.6rem;
  }
}
